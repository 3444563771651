import React, { useState, useEffect } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import useAuthState from '@shared/hooks/use-auth-state';
import { CircularProgress, TextField, InputLabel, MenuItem, Select } from '@material-ui/core';
import firebase from 'gatsby-plugin-firebase';
import Button from '@shared/components/button';
import Alert from '@shared/components/alert';

function Profile() {
  const profileDefaults = {
    email: '',
    country: '',
  };
  // const profile = firebase.firestore().doc(`customers/${user?.uid}`);
  const [isSaving, setIsSaving] = useState(false);
  const [profileLocal, setProfileLocal] = useState({ ...profileDefaults });
  const [lastProfileLocal, setLastProfileLocal] = useState({ ...profileLocal });
  const [showAlert, setShowAlert] = useState(false);
  const [user, userLoading] = useAuthState(firebase);
  const [userData, userDataLoading] = useDocumentDataOnce(
    firebase.firestore().doc(`customers/${user?.uid}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  // TODO: Move countries to styles dictionary
  const countries = [
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Brazil ', code: 'BR' },
    { name: 'Canada', code: 'CA' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Japan', code: 'JP' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Mexico ', code: 'MX' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Norway', code: 'NO' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'Italy', code: 'IT' },
    { name: 'Portugal', code: 'PT' },
  ];

  useEffect(() => {
    if (
      userData?.profile &&
      Object.keys(userData?.profile).length &&
      Object.keys(userData?.profile).find((da) => userData?.profile[da] !== '') &&
      !Object.keys(profileLocal).find((da) => profileLocal[da] !== '')
    ) {
      setProfileLocal(() => ({ ...userData.profile }));
      setLastProfileLocal(() => ({ ...userData.profile }));
    } else if (userData?.email && !profileLocal.email) {
      setProfileLocal(() => ({ ...profileLocal, email: userData?.email }));
    }

    if (user) {
      console.log('user', user);
    }
  }, [userData, profileLocal]);

  const onHandleCountryChanged = (e) => {
    const country = e.target.value;
    e.preventDefault();
    console.log('Value', e.target.value);
    setProfileLocal(() => ({ ...profileLocal, country }));
  };

  const onHandleSave = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    // console.log('Value', e.target.value);
    try {
      await firebase
        .firestore()
        .collection('customers')
        .doc(user?.uid)
        .set({ ...userData, profile: { ...userData.profile, ...profileLocal } }, { merge: true });

      setShowAlert(true);
      setLastProfileLocal({ ...profileLocal });

      setIsSaving(false);
    } catch (err) {
      console.log('Err', err);
      setIsSaving(false);
    }
  };

  return (
    <div>
      {userDataLoading || userLoading ? (
        <CircularProgress size={24} />
      ) : (
        <div>
          {/* <pre className="bg-blue-100">{JSON.stringify(profileLocal)}</pre>
          <pre className="bg-red-100">{JSON.stringify(lastProfileLocal)}</pre> */}
          <Alert show={showAlert} closeIn={4} onClose={() => setShowAlert(false)}>
            Saved
          </Alert>
          {profileLocal && (
            <form>
              <div className="w-1/2">
                <div className="mb-4">
                  <TextField
                    label="Email"
                    fullWidth
                    id="username"
                    type="text"
                    value={profileLocal.email}
                    placeholder="Username"
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <InputLabel id="country-selector-label" shrink>
                    Country
                  </InputLabel>
                  <Select
                    fullWidth
                    color="secondary"
                    labelId="country-selector-label"
                    id="country-selector"
                    displayEmpty
                    value={profileLocal.country || ''}
                    onChange={onHandleCountryChanged}
                  >
                    <MenuItem value="">Select Country</MenuItem>
                    {countries &&
                      countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* <TextField
                    label="Country"
                    fullWidth
                    id="country"
                    type="text"
                    value={profileLocal.country || ''}
                    onChange={onHandleCountryChanged}
                    placeholder="Country"
                  /> */}
                </div>
              </div>
              <Button
                onClick={onHandleSave}
                className="bg-primary text-white"
                disabled={
                  JSON.stringify(lastProfileLocal) === JSON.stringify(profileLocal) ||
                  userLoading ||
                  userDataLoading ||
                  isSaving
                }
              >
                <span>
                  Save {isSaving && <CircularProgress size={12} className="text-primary" />}
                </span>
              </Button>
            </form>
          )}
        </div>
      )}
    </div>
  );
}
export default Profile;
