import React from 'react';
import Layout from '@org/components/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Seo from '@org/components/seo';
import PrivateComponent from '@shared/components/private-component';
import Profile from '@shared/components/profile';
import Posts from '@shared/components/wp-components/posts';

const Page = ({ data: { wpPage: data } }) => {
  return (
    <Layout>
      <Helmet>
        {data.slug === 'join' && (
          <script src="https://actionnetwork.org/widgets/v3/form/joincitizenshareholders?format=js&source=widget"></script>
        )}
      </Helmet>
      <Seo title={data.title} />
      <div className="container mx-auto p-4">
        {data.title && data.slug !== 'join' && (
          <h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
        )}
        {data.content && <div dangerouslySetInnerHTML={{ __html: data.content }} />}
        {data.slug && data.slug === 'profile' && (
          <PrivateComponent>
            <Profile />
          </PrivateComponent>
        )}
        {data.slug && data.slug === 'blog' && <Posts />}
        {data.slug === 'join' && (
          // ID needed script to work
          <div
            id="can-form-area-joincitizenshareholders"
            className="w-full action-network-wrapper"
          ></div>
        )}
      </div>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query pageCustom($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      slug
    }
  }
`;

export default Page;
